import React from "react";

function Records() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="120"
            fill="none"
            viewBox="0 0 120 120"
        >
            <g fill="#DF8D46" clipPath="url(#clip0_228_1783)">
                <path d="M59.392 89.231h-8.993c-.545 0-.912-.333-.913-.828a.81.81 0 01.238-.605.922.922 0 01.658-.244h8.463v-6.562l-.443-.02-.133-.007a5.024 5.024 0 00-.32-.012H29.59c-.672-.025-.972-.339-.973-1.015V42.875c0-.876.27-1.142 1.15-1.142h12.506V37.65v-8.87c0-.476.056-.683.217-.79.231-.161.503-.257.784-.276 3.22-.013 6.431-.016 9.147-.016h10.361c1.677 0 2.967 0 4.182-.007a1.127 1.127 0 01.871.35c3.2 3.2 6.385 6.377 9.35 9.332a.998.998 0 01.327.788c-.013.724-.011 1.434-.009 2.185V41.73h12.104c.466 0 .772.082.936.245.165.164.245.479.245.951v36.858c0 .906-.262 1.168-1.17 1.168H60.559v6.602h4.67c1.282 0 2.563.001 3.845.004.425 0 .795.313.841.71a.842.842 0 01-.607.934 1.32 1.32 0 01-.32.027l-9.595.003zM30.327 79.228h58.754v-6.59H30.327v6.59zm47.181-8.318h11.567V43.439H77.502v2.35c0 1.484 0 3.02.005 4.528 0 .12-.01.129-.07.173-.47.35-.939.716-1.392 1.07l-.234.182V38.914h-8.475c-.743 0-1.047-.3-1.048-1.034V29.4H44v41.498h31.828V68.86c0-1.413 0-2.875.005-4.312 0-.052 0-.088.004-.113.542-.434 1.095-.86 1.674-1.306l-.003 7.78zm-47.177-.014h11.9V43.438H30.334l-.002 27.458zm37.667-33.678h6.198l-6.198-6.55v6.55z"></path>
                <path d="M63.248 68.68c-.379 0-.692-.15-.862-.408a.77.77 0 01-.053-.744 125.669 125.669 0 011.618-3.673c.078-.15.19-.28.326-.38 5.435-4.21 10.808-8.359 15.346-11.863.17-.142.38-.225.601-.235.316 0 .547.211.684.389.728.937 1.49 1.924 2.263 2.934.408.537.36.96-.17 1.37l-5.225 4.035c-3.299 2.547-6.71 5.181-10.06 7.778-.277.215-.614.257-1.003.306a761.455 761.455 0 01-2.615.368l-.85.123zM75.07 57.26l-.163.122-2.31 1.786c-2.133 1.653-4.34 3.361-6.526 5.018-.7.531-.998 1.224-1.244 1.895l-.294.8.832-.19c.16-.037.32-.06.49-.082.464-.066.99-.142 1.48-.535 1.388-1.11 2.823-2.21 4.212-3.276a1697.5 1697.5 0 001.67-1.285l2.243-1.736 1.425-1.102-1.354-1.755-.461.34zm1.784-1.382l1.366 1.767 3.19-2.472-1.365-1.764-3.19 2.469zM71.647 52.592h-23.52v1.636h23.52v-1.636zM71.646 47.184H48.127v1.64h23.519v-1.64zM64.826 59.73c-.192 0-.389-.013-.598-.025-.209-.012-.432-.026-.653-.026H48.124v-1.673H67.7a79.81 79.81 0 01-.604.466c-.178.138-.348.286-.513.43-.36.313-.699.61-1.061.734-.226.07-.462.101-.697.093zM61.405 41.755h-13.28v1.635h13.28v-1.635zM48.118 65.088V63.44h13.569a55.77 55.77 0 01-.735 1.639.689.689 0 01-.072.005c-2.343 0-4.652.004-7.397.004h-5.365zM81.14 75.011h-1.6v1.628h1.6V75.01zM85.309 75.005h-1.571v1.642h1.57v-1.642z"></path>
            </g>
            <path
                fill="#DF8D46"
                d="M83.963 89.212c-.233.012-11.23.012-11.463 0a.945.945 0 01-.588-.254.69.69 0 01-.219-.53.7.7 0 01.265-.512.96.96 0 01.608-.211c.433-.017 11.63-.017 12.062 0 .541.017.9.315.91.738.011.424-.343.745-.865.78-.233.017-.467 0-.7 0l-.01-.011zM76.271 92.289c-.233.012-34.308.012-34.54 0a.945.945 0 01-.588-.254.69.69 0 01-.22-.53.7.7 0 01.266-.512.959.959 0 01.607-.211c.433-.017 34.707-.017 35.14 0 .54.017.9.315.91.739.01.423-.343.744-.866.78-.233.016-.466 0-.7 0l-.009-.012zM35.575 89.212c.233.012 11.23.012 11.464 0a.945.945 0 00.588-.254.69.69 0 00.218-.53.7.7 0 00-.264-.512.959.959 0 00-.608-.211c-.433-.017-11.63-.017-12.063 0-.54.017-.899.315-.91.738-.01.424.343.745.866.78.233.017.467 0 .7 0l.009-.011zM87.846 88.458a.777.777 0 01-.77.773.785.785 0 01-.768-.775.755.755 0 01.476-.708.762.762 0 01.295-.056.76.76 0 01.767.766zM31.693 88.458a.777.777 0 00.77.773.785.785 0 00.768-.775.755.755 0 00-.476-.708.762.762 0 00-.296-.056.76.76 0 00-.766.766z"
            ></path>
            <circle
                cx="60"
                cy="60"
                r="59"
                stroke="#DF8D46"
                strokeWidth="2"
            ></circle>
            <defs>
                <clipPath id="clip0_228_1783">
                    <path
                        fill="#fff"
                        d="M0 0H62.173V61.538H0z"
                        transform="translate(28.616 27.692)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Records;
