import { ReactElement } from "react";
import colors from "@styles/variables/contentintelligenceVariables.module.scss";

import ContentCapture from "../svgs/contentCapture";
import Records from "../svgs/records";
import Automation from "../svgs/automation";
import Integrations from "../svgs/integrations";

import FeatureList from "@components/shared/featureList";

export default function EcmDetails(): ReactElement {
    const features = [
        {
            title: "Intelligent Content Capture",
            description:
                "Quickly and seamlessly capture, organize, and find content.",
            icon: <ContentCapture />,
            tool: "intelligent-data-capture",
        },
        {
            title: "Document & Records Management",
            description:
                "Promote collaboration and simplify records lifecycle management with all your content in one place.",
            icon: <Records />,
            tool: "document-and-records-management",
        },
        {
            title: "Process Automation",
            description:
                "Automate everyday business processes and gain intelligence that drives decisions.",
            icon: <Automation />,
            tool: "process-automation",
        },
        {
            title: "Integrations",
            description:
                "Connect with the enterprise apps your teams rely on everyday to stay productive.",
            icon: <Integrations />,
            tool: "integrations",
        },
    ];

    return (
        <FeatureList
            featureList={features}
            mainTitle="Enterprise Content Management (ECM)"
            mainDescription="We offer you powerful tools that capture and manage documents while also automating many of your organization's business processes."
            colors={{
                bgColor: colors.purple,
                mainTitleColor: "white",
                mainDescriptionColor: "white",
                titleColor: colors.orange,
                descriptionColor: "white",
                toolColor: "white",
                chevronColor: colors.orange,
            }}
            classes={{
                mainSectionClasses: "flex flex-col items-center lg:-mt-20",
                mainTitleClasses:
                    "text-center font-bold text-xl lg:text-4xl mb-4 mt-8",
                mainDescriptionClasses: "mb-14 text-center w-full md:w-1/2 ",
                innerSectionClasses:
                    "flex justify-center text-center flex-wrap",
                oneBoxClasses:
                    "md:h-80 flex flex-col justify-center w-full md:w-1/2 lg:w-1/4 items-center mb-12 lg:mb-20",
                iconClasses: " ",
                titleClasses: "mt-4",
                descriptionClasses:
                    "font-light text-sm text-white text-center w-4/5 mx-auto mt-2",
            }}
        />
    );
}
